<template>
	<v-main>
		<v-container fluid fill-height>
			<v-row align-center justify-center>
				<v-col xs12 sm8 md4>
					<v-card>
						<v-toolbar dark>
							<v-toolbar-title>Connection</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form>
								<v-text-field name="Utilisateur" label="Email" type="email"></v-text-field>
								<v-text-field name="Mot de Passe" label="Password" type="password" v-model="password">
								</v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn to="/dashboard">Login</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
	export default {
		name: 'LoginForm',
	}
</script>